import React, { useContext } from "react"
import Layout from "../../components/layout"
import NullIf from "../../services/NullIf"

import styles from "./item.module.scss"
import ThemeContext from '../../context/ThemeContext'
import SEO from "../../components/seo"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import CasinoHelper from "../../services/casino-bonus-helpers"
import MainSearch from "../../components/main-search/main-search"
import { graphql } from "gatsby"
import localize from "../../components/localize"
import ContentWrapper from "../../components/text-helpers/content-wrapper"

const Content = ({ bonuses, bonusType, locale }) => {
  bonuses = bonuses
    .filter(x => NullIf.isNotNullOrEmpty(x))
    .filter(x => NullIf.isNotNullOrEmpty(x.bonuses))
    .filter(x => NullIf.isNotNullOrEmpty(x.logo))
    .filter(casino => {
      let casinoBonusFlattened = [].concat.apply([], casino.bonuses)
      if (!NullIf.isNotNullOrEmpty(casinoBonusFlattened)) return false
      // we have an array of casino bonuses now
      casinoBonusFlattened = casinoBonusFlattened.filter(y =>
        NullIf.isNotNullOrEmpty(y.type)
      )

      return casinoBonusFlattened.some(
        z => NullIf.isNotNullOrEmpty(z.type) && z.type._id === bonusType
      )
    })

  const isFreeSpins = CasinoHelper.IsFreespinNoDepositBonus(bonusType);
  if (isFreeSpins)
    bonuses.sort((a, b) =>{
        if (CasinoHelper.GetFreespinNoDepositBonus(a).freespinsAmount < CasinoHelper.GetFreespinNoDepositBonus(b).freespinsAmount)
          return 1;
        if (CasinoHelper.GetFreespinNoDepositBonus(a).freespinsAmount > CasinoHelper.GetFreespinNoDepositBonus(b).freespinsAmount)
          return -1;

        return 0;
      }
    );
  return (
    <div>
      { <MainSearch locale={locale} bonuses={bonuses}  forceSpecificBonusType={bonusType} /> }
    </div>
  )
}

const CasinoBonusItem = ({ pageContext, location, data }) => {
  let site = useContext(ThemeContext)
  let bonuses = site.bonuses
  let { bonusType, locale } = pageContext

  let bonusTypeData = data.sanityBonusType;

  return (
    <Layout breadcrumbNameOverride={bonusTypeData.breadcrumbName} silo={data.sanitySilo} pageContext={pageContext} location={location}>

      <SEO location={location} title={bonusTypeData.seoTitle} description={bonusTypeData.seoMeta} />
      <ContentWrapper
        title={bonusTypeData.h1Tag}
        header={bonusTypeData._rawHeaderText}
        body={bonusTypeData._rawCombinedBody}
        footer={bonusTypeData._rawFooterText}
        headerClassName={`gambleGeneralWrapper ${styles.casinoBonusPageHeader}`} bodyClassName={`gambleGeneralWrapper ${styles.casinoBonusPageBody}`}>
        <Content bonuses={bonuses} locale={locale} bonusType={bonusTypeData._id}/>
      </ContentWrapper>
    </Layout>
  )
}

export default localize(CasinoBonusItem)
export const pageQuery = graphql`
  query BasicBonusTypesQuery($bonusType: String!) {
    sanitySilo (_id: {eq: "3aacbadb-2f40-40ba-bcb8-f6deeb25edb4"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
  sanityBonusType (
      _id: { eq: $bonusType }) {
      _id
      breadcrumbName {
          ...LocaleString
      }
      name {
          ...LocaleString
      }
      h1Tag {
          ...LocaleString
      }
      seoMeta{
          ...LocaleString
      }
      seoTitle{
          ...LocaleString
      }
      _rawCombinedBody(resolveReferences: {maxDepth: 10})
      _rawFooterText(resolveReferences: {maxDepth: 10})
      _rawHeaderText(resolveReferences: {maxDepth: 10})
  }
}
`
